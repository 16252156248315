import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';
window.Buffer = Buffer;
class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/reports', state: 'reportElementsMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/broadcast', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  encodeUserEmail = (userEmail) => {
    const base64Encoded = Buffer.from(userEmail).toString('base64');
    const replacedChars = base64Encoded
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const trimmed = replacedChars.replace(/=+$/, '');

    return trimmed;
  };
  render() {
    console.log(this.props.data.user)
    let roles = ''
    if (this.props.data.user) {
      roles = this.props.data.user
    }
    const second_website_url = 'https://content.acuizen.com';
    const email = "bala.murali@agilitaslearning.com"
    const company = "REC Solar"
    const email_encode = this.encodeUserEmail(email)
    const company_encode = this.encodeUserEmail(company)
    const url = second_website_url + '/sso.php?key=' + email_encode + '&detail=' + company_encode

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
         {!this.props.data.loader ?
        <ul className="nav">
       

          {roles.roles.includes('all') && <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
            <li className={this.isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/company">
                <i className="mdi mdi-account-multiple-plus menu-icon"></i>
                <span className="menu-title">Company</span>
              </Link>
            </li>
            <li className={this.isPathActive('/services') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/services">
                <i className="mdi  mdi-briefcase-check menu-icon"></i>
                <span className="menu-title">Services</span>
              </Link>
            </li>
            <li className={this.isPathActive('/hazards') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/hazards">
                <i className="mdi mdi-alert-outline menu-icon"></i>
                <span className="menu-title">Hazards</span>
              </Link>
            </li>

          </>}
          {roles !== '' && roles.applicationRoles.includes('admin') ? <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
            <li className={this.isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-cube-outline menu-icon"></i>
                <span className="menu-title">Curate</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.appsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/knowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/checklist') ? 'nav-link active' : 'nav-link'} to="/apps/checklist">Checklist</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/forms') ? 'nav-link active' : 'nav-link'} to="/apps/forms">Form</Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
                <i className="mdi  mdi-account menu-icon"></i>
                <span className="menu-title">Users</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.usersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/users/enduser') ? 'nav-link active' : 'nav-link'} to="/users/enduser">Users</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/users/entity') ? 'nav-link active' : 'nav-link'} to="/users/entity">Admin</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/users/curator') ? 'nav-link active' : 'nav-link'} to="/users/curator">Curator</Link></li>

                </ul>
              </Collapse>
            </li>
            {/* <li className={ this.isPathActive('/users') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/widgets">
              <i className="mdi  mdi-account menu-icon"></i>
              <span className="menu-title">Users</span>
            </Link>
          </li> */}
            <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-floor-plan"></i>
                <span className="menu-title">Assignment</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.basicUiMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/knowledge') ? 'nav-link active' : 'nav-link'} to="/basic-ui/knowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/checklist') ? 'nav-link active' : 'nav-link'} to="/basic-ui/checklist">Checklist</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/document') ? 'nav-link active' : 'nav-link'} to="/basic-ui/document">Document</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/form') ? 'nav-link active' : 'nav-link'} to="/basic-ui/form">Form</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/curator') ? 'nav-link active' : 'nav-link'} to="/basic-ui/curator"> To Curator</Link></li>
                  {/* <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/badges') ? 'nav-link active' : 'nav-link' } to="/basic-ui/badges">Action</Link></li> */}

                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/groups') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/groups">
                <i className="menu-icon mdi mdi-account-multiple"></i>
                <span className="menu-title">Groups</span>

              </Link>

            </li>
            <li className={this.isPathActive('/broadcast') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-bell-ring"></i>
                <span className="menu-title">Broadcast</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.formElementsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/one-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/one-way">One Way</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/two-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/two-way">Two Way</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/template') ? 'nav-link active' : 'nav-link'} to="/broadcast/template">Template</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/list') ? 'nav-link active' : 'nav-link'} to="/broadcast/list">Analytics</Link></li>
                </ul>
              </Collapse>
            </li>
            {/* <li className={ this.isPathActive('/certificate') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/certificate">
              <i className="menu-icon mdi mdi-certificate"></i>
              <span className="menu-title">Certificate</span>
             
            </Link>
         
          </li> */}
            <li className={this.isPathActive('/insight') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/insight">
                <i className="menu-icon mdi mdi-poll"></i>
                <span className="menu-title">Insights</span>

              </Link>

            </li>
            <li className={this.isPathActive('/reports') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.reportElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('reportElementsMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-file-chart"></i>
                <span className="menu-title">Reports</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reportElementsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/knowledge') ? 'nav-link active' : 'nav-link'} to="/reports/knowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/checklists') ? 'nav-link active' : 'nav-link'} to="/reports/checklists">Checklists</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/forms') ? 'nav-link active' : 'nav-link'} to="/reports/forms">Forms</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/documents') ? 'nav-link active' : 'nav-link'} to="/reports/documents">Documents</Link></li>
                </ul>
              </Collapse>
            </li>
            {/* <li className={ this.isPathActive('/report') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/report">
              <i className="menu-icon mdi mdi-file-chart"></i>
              <span className="menu-title">Reports</span>
            
            </Link>
         
          </li> */}
            {/* <li className={ this.isPathActive('/notifications') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/notifications">
              <i className="menu-icon mdi  mdi-folder"></i>
              <span className="menu-title">MyAZ</span>
            </Link>
          </li> */}
            <li className={'nav-item'}>
              <a className="nav-link" href={url} target={"_blank"} rel="noopener noreferrer">
                <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
                <span className="menu-title">H5P</span>

              </a>

            </li>
          </> : roles !== '' && roles.applicationRoles.includes('entity') ? <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
            <li className={this.isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-cube-outline menu-icon"></i>
                <span className="menu-title">Curate</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.appsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/knowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/checklist') ? 'nav-link active' : 'nav-link'} to="/apps/checklist">Checklist</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/forms') ? 'nav-link active' : 'nav-link'} to="/apps/forms">Form</Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
                <i className="mdi  mdi-account menu-icon"></i>
                <span className="menu-title">Users</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.usersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/users/enduser') ? 'nav-link active' : 'nav-link'} to="/users/enduser">Users</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/users/curator') ? 'nav-link active' : 'nav-link'} to="/users/curator">Curator</Link></li>

                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-floor-plan"></i>
                <span className="menu-title">Assignment</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.basicUiMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/knowledge') ? 'nav-link active' : 'nav-link'} to="/basic-ui/knowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/curator') ? 'nav-link active' : 'nav-link'} to="/basic-ui/curator">Curator</Link></li>
                  {/* <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/badges') ? 'nav-link active' : 'nav-link' } to="/basic-ui/badges">Action</Link></li> */}

                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/broadcast') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-bell-ring"></i>
                <span className="menu-title">Broadcast</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.formElementsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/one-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/one-way">One Way</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/two-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/two-way">Two Way</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/template') ? 'nav-link active' : 'nav-link'} to="/broadcast/template">Template</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/broadcast/list') ? 'nav-link active' : 'nav-link'} to="/broadcast/list">Analytics</Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/insight') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/insight">
                <i className="menu-icon mdi mdi-poll"></i>
                <span className="menu-title">Insights</span>

              </Link>

            </li>
            <li className={this.isPathActive('/reports') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.reportElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('reportElementsMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-file-chart"></i>
                <span className="menu-title">Reports</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reportElementsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/knowledge') ? 'nav-link active' : 'nav-link'} to="/reports/knowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/checklists') ? 'nav-link active' : 'nav-link'} to="/reports/checklists">Checklists</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/forms') ? 'nav-link active' : 'nav-link'} to="/reports/forms">Forms</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/reports/documents') ? 'nav-link active' : 'nav-link'} to="/reports/documents">Documents</Link></li>
                </ul>
              </Collapse>
            </li>
            {/* <li className={ this.isPathActive('/notifications') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/notifications">
              <i className="menu-icon mdi  mdi-folder"></i>
              <span className="menu-title">MyAZ</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/insight') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/insight">
              <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
              <span className="menu-title">H5P</span>
              
            </Link>
         
          </li>
          <li className={ this.isPathActive('/report') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/report">
              <i className="menu-icon mdi mdi-file-chart"></i>
              <span className="menu-title">Reports</span>
            
            </Link>
         
          </li> */}
            <li className={'nav-item'}>
              <a className="nav-link" href={url} target={"_blank"} rel="noopener noreferrer">
                <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
                <span className="menu-title">H5P</span>

              </a>

            </li>
          </> : roles !== '' && roles.applicationRoles.includes('curator') ? <>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
            <li className={this.isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-cube-outline menu-icon"></i>
                <span className="menu-title">Curate</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.appsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/curatorknowledge">Knowledge</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/checklist') ? 'nav-link active' : 'nav-link'} to="/apps/checklist">Checklist</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/apps/forms') ? 'nav-link active' : 'nav-link'} to="/apps/forms">Form</Link></li>
                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                <i className="menu-icon mdi mdi-floor-plan"></i>
                <span className="menu-title">Assignment</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.basicUiMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/knowledge') ? 'nav-link active' : 'nav-link'} to="/basic-ui/knowledge">Knowledge</Link></li>
                  {/* <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/curator') ? 'nav-link active' : 'nav-link'} to="/basic-ui/curator">Curator</Link></li> */}
                  {/* <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/badges') ? 'nav-link active' : 'nav-link' } to="/basic-ui/badges">Action</Link></li> */}

                </ul>
              </Collapse>
            </li>
            <li className={this.isPathActive('/insight') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/insight">
                <i className="menu-icon mdi mdi-poll"></i>
                <span className="menu-title">Insights</span>

              </Link>

            </li>
            <li className={'nav-item'}>
              <a className="nav-link" href={url} target={"_blank"} rel="noopener noreferrer">
                <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
                <span className="menu-title">H5P</span>

              </a>

            </li>

          </> : ''}




        </ul> :''}
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}
const mapStateToProps = state => {
  return {
    data: state.details
  }

}
export default connect(mapStateToProps)(withRouter(Sidebar));