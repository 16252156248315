import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/index";
import setupInterceptors from "./app/services/setupInterceptors";

import $ from 'jquery';
import 'jquery';
import '../node_modules/popper.js/dist/popper.min';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/jquery-ui-dist/jquery-ui.min';
import '../node_modules/jquery-ui-dist/jquery-ui.min.css';
import '../node_modules/jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon';
import '../node_modules/jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
window.$ = window.jQuery = window.jquery = $;
//render app to root
root.render(
<BrowserRouter>
  <Provider store={store}>
    <App />
  </Provider>
</BrowserRouter>);


// ReactDOM.render(
//   <BrowserRouter>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
setupInterceptors(store)
serviceWorker.unregister();
